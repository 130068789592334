export default function Process(props) {
    return <div className="content-wrapper">
        <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">My Process</h4>
                        <p className="card-description">
                            Processes that I've started but not stopped yet.
                        </p>
                        <div className="table-responsive pt-3">
                            <table className="table table-bordered" id="process-table">
                                <thead>
                                <tr>
                                    <th>MTPL&nbsp;No</th>
                                    <th>Part&nbsp;No</th>
                                    <th>Revision&nbsp;No</th>
                                    <th>Sequence</th>
                                    <th>Machine</th>
                                    <th>Work&nbsp;center</th>
                                    <th>Start</th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.process.map(item => {
                                    return <tr key={item.id} id={"log-" + item.id} onClick={() => {
                                        props.setPayload('log_id', item.id);
                                        let table = document.getElementById("process-table");
                                        for (let i = 0; i < table.rows.length; i++) {
                                            let row = table.rows[i];
                                            row.classList.remove('table-success');
                                        }
                                        document.getElementById("log-" + item.id).classList.add('table-success');
                                    }}>
                                        <td>{item.part_revision ? item.mtpl_no : 'deleted'}</td>
                                        <td>{item.part_revision ? item.part_revision : 'deleted'}</td>
                                        <td>{item.part_revision ? item.revision_no : 'deleted'}</td>
                                        <td>{item.sequence}</td>
                                        <td>{item.machine}</td>
                                        <td>{item.work_center}</td>
                                        <td>{item.start_time}</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}