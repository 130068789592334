import React from "react";

function Action(props) {
    let showSpinner = () => {
        document.getElementById('action-spinner').style.display = 'inline-block';
    }
    return (
        <React.Fragment>
            <li className="nav-item mr-1">
                <button onClick={() => {
                    showSpinner();
                    props.setPayload('start', true);
                    props.createLog();
                }}
                        className="btn btn-success btn-sm btn-icon-split">
                    <span className="icon text-white-50">
                                            <i className="mdi mdi-check-circle"> </i>
                                        </span>
                    <span className="text">Start</span>
                </button>
            </li>
            <li className="nav-item">
                <div align="center" style={{'display': 'inline-block'}}>
                    <div id="action-spinner" style={{'display': 'none'}} className="text-primary spinner-border"
                         role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </li>
            <li className="nav-item mr-4">
                <button onClick={() => props.checkPayload()} className="btn-sm btn btn-danger btn-icon-split"
                        data-toggle="modal"
                        data-target="#stopModal">
                    <span className="icon text-white-50">
                                            <i className="mdi mdi-alert-circle"> </i>
                                        </span>
                    <span className="text">Stop</span>
                </button>
            </li>

            <div className="modal fade" id="stopModal" style={{'zIndex': "1"}} role="dialog"
                 aria-labelledby="stopModalLabel"
                 aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" id="stop-modal">
                        <div className="modal-header">
                            <h5 className="modal-title" id="stopModalLabel">More information</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <nav className="navbar navbar-expand navbar-light bg-light mb-4">
                                <span className="navbar-brand">Reason : <strong id="selected-reason"> </strong></span>
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item dropdown">
                                        <span className="nav-link dropdown-toggle" id="navbarDropdown"
                                              role="button" data-toggle="dropdown" aria-haspopup="true"
                                              aria-expanded="false" style={{'cursor':'pointer'}}>
                                            Select
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right animated--fade-in"
                                             aria-labelledby="navbarDropdown">
                                            {props.items && props.items.map(item => {
                                                return (
                                                    <span className="dropdown-item" key={item.description} style={{'cursor':'pointer'}}
                                                          onClick={() => {
                                                              props.setPayload('reason', item.id);
                                                              document.getElementById('selected-reason').innerHTML = item.description
                                                          }}>
                                                        {item.description}
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                            <input type="number" className="form-control" id="rejection_quantity"
                                   onChange={(e) => props.setPayload('rejection_quantity', e.target.value)}
                                   placeholder="Rejected pieces quantity (optional)"/>
                            <br/>
                            <textarea id="remarks" onChange={(e) => props.setPayload('remarks', e.target.value)}
                                      className="form-control"
                                      placeholder="Remarks (optional)">
                            </textarea>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" id="cancel-log"
                                    data-dismiss="modal">Cancel
                            </button>
                            <button className="btn btn-danger" onClick={() => {
                                let r = window.confirm("Do you confirm the information\n you've entered is correct?");
                                if (r) {
                                    props.setPayload('stop', true);
                                    showSpinner();
                                    props.createLog()
                                }
                            }}>Stop</button>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>


    );
}

export default Action;
