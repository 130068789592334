import React from "react";

function Projects(props) {
    return (
        <div className="col grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="align-content-around">MTPL&nbsp;No
                        <i className="mdi mdi-lock-open-outline text-success" id="project-lock"
                           onClick={() => props.lockUnlock("project")}
                           style={{'cursor': 'pointer'}}> </i></h4>
                    <p className="card-description">
                        Select mtpl no
                    </p>
                    <div className="table-responsive" style={{'height':'50vh'}} id="project-div">
                        <table className="table table-bordered">
                            <tbody>
                            {props.items && props.items.map(item => {
                                if (props.project !== item.id) {
                                    return (
                                        <tr key={item.id + '-' + item.mtpl_no}
                                            onClick={() => {
                                                props.lockUnlock("project");
                                                props.selectProject(item.id)
                                            }}>
                                            <td>{item.mtpl_no}</td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={item.id + '-' + item.mtpl_no}
                                            className="table-success">
                                            <td>{item.mtpl_no}</td>
                                        </tr>
                                    );
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default Projects;
