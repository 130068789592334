import React from 'react';

function Parts(props) {
    return (
        <div className="col grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="align-content-around">Part&nbsp;No
                        <i className="mdi mdi-lock-open-outline text-success" id="part-lock"
                           onClick={() => props.lockUnlock("part")}
                           style={{'cursor': 'pointer'}}> </i></h4>
                    <p className="card-description">
                        Select part no
                    </p>
                    <div className="table-responsive" style={{'height':'50vh'}} id="part-div">
                        <table className="table table-bordered">
                            <tbody>
                            {props.items.length === 0 && props.project &&
                            <tr>
                                <td>
                                    <div id="part-spinner" className="spinner-border"
                                         role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </td>

                                <td>
                                    <small>
                                        Loading will not stop if no items found!
                                    </small>
                                </td>
                            </tr>
                            }
                            {props.items && props.items.map(item => {
                                if (item.id !== props.part) {
                                    return (
                                        <tr key={item.id + '-' + item.part_no + '-' + item.revision_no}>
                                            <td onClick={() => {
                                                props.lockUnlock("part");
                                                props.setPayload('part_revision', item.id);
                                                props.setPart(item.id);
                                            }} className="list-group-item list-group-item-light">
                                                <small>
                                                    {item.part_no}
                                                    <br/>
                                                    <strong>({'R' + item.revision_no + ' Qty : ' + item.quantity})</strong>
                                                </small>
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={item.id + '-' + item.part_no + '-' + item.revision_no}>
                                            <td className="list-group-item list-group-item-success">
                                                <small>
                                                    {item.part_no}
                                                    <br/>
                                                    <strong>({'R' + item.revision_no + ' Qty : ' + item.quantity})</strong>
                                                </small>
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Parts;