import React from 'react';
import Operation from "./Operation";
import Cookies from 'universal-cookie';
import Login from "./Login";
import Action from "./Action";
import Process from "./Operation/Process";

function App() {
    const serviceUrl = 'https://timesheetadmin.macpro-technologies.com/';
    //const serviceUrl = 'http://localhost:8000/';
    const cookies = new Cookies();
    let token = null;
    if (cookies.get('user')) {
        token = cookies.get('user').token;
    }
    let [payLoad, setPayloadState] = React.useState({'token': token});
    let [projects, setProjects] = React.useState([]);
    let [parts, setParts] = React.useState([]);
    let [workCenters, setWorkCenters] = React.useState([]);
    let [reasons, setReasons] = React.useState([]);
    let [machines, setMachines] = React.useState([]);
    let [project, setProject] = React.useState(null);
    let [part, setPart] = React.useState(null);
    let [workCenter, setWorkCenter] = React.useState(null);
    let [machine, setMachine] = React.useState(null);
    let [sequence, setSequence] = React.useState(null);
    let [starState, setStarState] = React.useState([]);
    let [clickedProcess, setClickedProcess] = React.useState(false);
    let [clickedOperation, setClickedOperation] = React.useState(false);
    let myProcesses = () => {
        fetch(serviceUrl + 'process/' + token, {
            method: 'GET',
        }).then(resp => resp.json()).then(data => {
            if (data.data === false) {
                console.log(data.message);
            } else {
                setProcess(data.data);
            }
        });
    }
    let toggle = (i) => {
        if (i === 1) {
            setClickedProcess(false);
            setClickedOperation(true);
        } else {
            myProcesses();
            setClickedProcess(true);
            setClickedOperation(false);
        }
        if (document.body.classList.length === 0) {
            document.body.classList.add('sidebar-icon-only');
        }
        if (document.getElementById('sidebar').classList.contains('active')) {
            document.getElementById('sidebar').classList.remove('active')
        }
    }
    let [process, setProcess] = React.useState([]);
    let [pin, setPin] = React.useState('');
    let lockUnlockDiv = (i) => {
        let div = i + "-div";
        let lock = i + "-lock";
        if (document.getElementById(div).style.pointerEvents === 'none') {
            document.getElementById(div).style.pointerEvents = '';
            document.getElementById(div).style.opacity = 1;
            document.getElementById(lock).classList.remove('mdi-lock');
            document.getElementById(lock).classList.add('mdi-lock-open-outline');
            document.getElementById(lock).classList.remove('text-danger');
            document.getElementById(lock).classList.add('text-success');
        } else {
            document.getElementById(div).style.pointerEvents = 'none';
            document.getElementById(div).style.opacity = 0.4;
            document.getElementById(lock).classList.remove('mdi-lock-open-outline');
            document.getElementById(lock).classList.add('mdi-lock');
            document.getElementById(lock).classList.remove('text-success');
            document.getElementById(lock).classList.add('text-danger');
        }
    }
    let cancelResetFunc = () => {
        document.getElementById('pin3').value = '';
        document.getElementById('pin2').value = '';
        document.getElementById('reset-spinner').style.display = 'none';
        document.getElementById('reset-btn').style.display = '';
    }
    let resetPassword = () => {
        let pin = document.getElementById('pin2').value;
        if (pin === document.getElementById('pin3').value && pin.length === 4) {
            document.getElementById('reset-btn').style.display = 'none';
            document.getElementById('reset-spinner').style.display = '';
            fetch(serviceUrl + 'reset_password/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "token": token,
                    "pin": pin,
                }),
            }).then(resp => resp.json()).then(resp => {
                if (resp.data === true) {
                    alert('Successfully reset pin!');
                } else {
                    alert('Failed to reset pin!');
                }
                document.getElementById('cancel-reset').click();
            })
        } else {
            alert('Pin not valid!');
        }

    }
    let logOut = () => {
        let r = window.confirm('Are you sure?');
        if (r) {
            cookies.remove('user', {path: '/'});
            window.location.reload();
        }
    }
    let setPayload = (field, value) => {
        if (field !== null && value !== null) {
            if (field === 'start') {
                payLoad['stop'] = false;
            } else if (field === 'stop') {
                payLoad['start'] = false;
            }
            payLoad[field] = value;
        }
        setPayloadState(payLoad);
    }
    let checkPayload = () => {
        if (!payLoad.part_revision || !payLoad.machine || !payLoad.sequence) {
            if (!payLoad.log_id) {
                alert('Some fields not selected!');
                setTimeout(function () {
                    document.getElementById('cancel-log').click();
                }, 600)
            }
        }
    }
    let createLog = () => {
        let dontSend = true;
        if (payLoad.log_id) {
            dontSend = false;
        } else if (payLoad.part_revision && payLoad.machine && payLoad.sequence) {
            dontSend = false;
        }
        if (dontSend) {
            alert('Some fields not selected!');
            document.getElementById('action-spinner').style.display = 'none';
        } else {
            document.getElementById('rejection_quantity').value = '';
            document.getElementById('remarks').value = '';
            document.getElementById('selected-reason').innerHTML = '';
            document.getElementById('cancel-log').click();
            setPayloadState(payLoad);
            fetch(serviceUrl + 'create_log/', {
                method: 'POST',
                body: JSON.stringify(payLoad),
            }).then(resp => resp.json()).then(resp => {
                if (resp.data === false) {
                    alert(resp.message);
                    if (resp.error) {
                        console.log(resp.error);
                    }
                } else {
                    if (payLoad.start === true) {
                        alert('Process started!');
                    } else if (payLoad.stop === true) {
                        alert('Process stopped!');
                    }
                }
                setPayloadState({'token': token});
            }).then(() => {
                setParts([]);
                setMachines([]);
                setTimeout(function () {
                    document.getElementById('action-spinner').style.display = 'none';
                    if (!clickedProcess) {
                        const items = ['project', 'part', 'work-center', 'machine', 'sequence'];
                        items.forEach(function (item) {
                            lockUnlockDiv(item);
                        });
                    } else {
                        let table = document.getElementById("process-table");
                        for (let i = 0; i < table.rows.length; i++) {
                            let row = table.rows[i];
                            row.classList.remove('table-success');
                        }
                        toggle(2);
                    }
                    setProject(null);
                    setPart(null);
                    setSequence(null);
                    setWorkCenter(null);
                    setMachine(null);
                }, 500);
            })
        }
    }
    let selectProject = (p_id) => {
        if (p_id) {
            setProject(p_id);
            setParts([]);
            fetch(serviceUrl + 'part/' + p_id, {
                method: 'GET',
            }).then(resp => resp.json()).then(resp => {
                if (resp.data === false) {
                    console.log(resp.message);
                }
                setParts(resp.data);
                delete payLoad['part_revision'];
            })
        }
    }
    let selectWorkCenter = (w_id) => {
        if (w_id) {
            setWorkCenter(w_id);
            setMachines([]);
            fetch(serviceUrl + 'machine/' + w_id, {
                method: 'GET',
            }).then(resp => resp.json()).then(resp => {
                if (resp.data === false) {
                    console.log(resp.message);
                }
                setMachines(resp.data);
                delete payLoad['machine'];
            })
        }
    }
    React.useEffect(() => {
        fetch(serviceUrl + 'work_center/', {
            method: 'GET',
        }).then(resp => resp.json()).then(resp => setWorkCenters(resp.data))
        fetch(serviceUrl + 'project/', {
            method: 'GET',
        }).then(resp => resp.json()).then(resp => setProjects(resp.data))
        fetch(serviceUrl + 'reason/', {
            method: 'GET',
        }).then(resp => resp.json()).then(resp => setReasons(resp.data))

    }, []);
    if (token !== null) {
        return (
            <React.Fragment>
                <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <div className="navbar-brand-wrapper d-flex justify-content-center">
                        <div
                            className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
                            <a className="navbar-brand brand-logo" href=""><img src="assets/images/logo.png"
                                                                                alt="logo"/></a>
                            <a className="navbar-brand brand-logo-mini" href=""><img
                                src="assets/images/logo-mini.png" alt="logo"/></a>
                            <button className="navbar-toggler navbar-toggler align-self-center" type="button"
                                    data-toggle="minimize">
                                <span className="mdi mdi-sort-variant"> </span>
                            </button>
                        </div>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                        <ul className="navbar-nav mr-lg-4 w-100">
                            <li className="nav-item nav-search d-none d-lg-block w-100">
                                MacPro-Timesheet&nbsp;
                            </li>
                        </ul>
                        <ul className="navbar-nav navbar-nav-right">
                            <Action items={reasons} checkPayload={checkPayload} setPayload={setPayload}
                                    createLog={createLog}/>
                            <li className="nav-item nav-profile dropdown">
                                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
                                   id="profileDropdown">
                                    <img src="assets/images/user.png" alt="profile"/>
                                    <span className="nav-profile-name">{cookies.get('user').name}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown"
                                     aria-labelledby="profileDropdown">
                                    <a className="dropdown-item" data-toggle="modal"
                                       data-target="#resetPinModal">
                                        <i className="mdi mdi-settings text-primary"> </i>
                                        Reset Pin
                                    </a>
                                    <a className="dropdown-item" onClick={() => logOut()}>
                                        <i className="mdi mdi-logout text-primary"> </i>
                                        Logout
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                                type="button" id="sidebar-button"
                                data-toggle="offcanvas">
                            <span className="mdi mdi-menu"> </span>
                        </button>
                    </div>
                </nav>
                <div className="container-fluid page-body-wrapper">
                    <nav className="sidebar sidebar-offcanvas" id="sidebar">
                        <ul className="nav">
                            <li className="nav-item">
                                <span style={{'cursor': 'pointer'}} className="nav-link" onClick={() => toggle(1)}>
                                    <i className="mdi mdi-home menu-icon"> </i>
                                    <span className="menu-title">Operation</span>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span style={{'cursor': 'pointer'}} className="nav-link" onClick={() => toggle(2)}>
                                    <i className="mdi mdi-engine menu-icon"> </i>
                                    <span className="menu-title">My Process</span>
                                </span>
                            </li>
                        </ul>
                    </nav>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            {clickedOperation &&
                            <Operation lockUnlockDiv={lockUnlockDiv} setPayload={setPayload} checkPayload={checkPayload}
                                       createLog={createLog} selectProject={selectProject} sequence={sequence}
                                       projects={projects}
                                       selectWorkCenter={selectWorkCenter} token={token} cookies={cookies}
                                       workCenter={workCenter} setSequence={setSequence} setWorkCenter={setWorkCenter}
                                       reasons={reasons} machines={machines} project={project} part={part}
                                       machine={machine} setPart={setPart} setMachine={setMachine}
                                       serviceUrl={serviceUrl} parts={parts} workCenters={workCenters}/>
                            }
                            {clickedProcess &&
                            <Process process={process} setPayload={setPayload} checkPayload={checkPayload}
                                     createLog={createLog}/>}
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span
                                    className="text-muted d-block text-center text-sm-left d-sm-inline-block"> </span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">MacPro-Timesheet By <a
                                    href="https://www.elvesonthecloud.com/"
                                    target="_blank">Elves On The Cloud</a></span>
                            </div>
                        </footer>
                    </div>
                </div>

                <div className="modal fade" id="resetPinModal" tabIndex="-1" role="dialog"
                     aria-labelledby="resetPinModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="resetPinModalLabel">Reset your pin</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <input type="number" maxLength="4" id="pin2"
                                       className="form-control form-control-user"
                                       placeholder="New Pin"/>
                                <br/>
                                <input type="number" maxLength="4" id="pin3"
                                       className="form-control form-control-user"
                                       placeholder="Confirm Pin"/>

                            </div>
                            <div className="modal-footer">
                                <strong><small>4 digit pin required</small></strong>
                                <button className="btn btn-secondary" onClick={() => cancelResetFunc()}
                                        id="cancel-reset" type="button"
                                        data-dismiss="modal">Cancel
                                </button>
                                <span className="btn btn-success" id="reset-btn" onClick={() => resetPassword()}
                                >Reset</span>
                                <div id="reset-spinner" style={{'display': 'none'}} className="spinner-border"
                                     role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    } else {
        return <Login cookies={cookies} serviceUrl={serviceUrl} pin={pin} starState={starState} setPin={setPin}
                      setStarState={setStarState}/>
    }
}

export default App;
