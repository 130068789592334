export default function Login(props) {

    let login = () => {
        if (props.pin.length === 4) {
            document.getElementById('login-btn').style.display = 'none';
            document.getElementById('login-spinner').style.display = '';
            fetch(props.serviceUrl + 'login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "emp_id": document.getElementById('emp_id').value,
                    "pin": props.pin,
                }),
            }).then(resp => resp.json()).then(resp => {
                if (resp.data === true) {
                    props.cookies.set('user', resp, {path: '/'});
                } else {
                    alert('Failed to authenticate!');
                    document.getElementById('pin1').value = '';
                    props.cookies.remove('user', {path: '/'});
                }
            }).then(() => window.location.reload());
        } else {
            alert('4 digit pin required!');
        }
        props.setPin('');
        props.setStarState([]);
    }
    let starsFunc = (evt) => {
        if ([8, 46].includes(evt.keyCode)) {
            props.setStarState(props.starState.slice(0, -1));
            props.setPin(props.pin.slice(0, -1));
        } else {
            if (props.pin.length < 4) {
                let i = evt.target.value;
                props.setStarState(props.starState.concat(['*']));
                props.setPin(props.pin + i.toString());
            }
            evt.target.value = '';
        }
    }
    return <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                    <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                        <div className="brand-logo">
                            <img src="assets/images/logo.png" alt="logo"/>
                        </div>
                        <h4>Macpro-Timesheet</h4>
                        <small className="font-weight-light">DO NOT FORGET TO LOG OUT AFTER USE.</small>
                        <form className="pt-3" method="post" action=".">
                            <div className="form-group">
                                <input type="number" className="form-control form-control-lg"
                                       id="emp_id" placeholder="Enter Employee Id"/>
                            </div>
                            <center>
                                <span className="alert alert-dark" id="stars" style={{'height': '5px'}}>
                                    {props.starState}
                                </span>
                            </center>
                            <br/>
                            <div className="form-group">
                                <input type="number" maxLength="4" id="pin1" onKeyUp={(evt) => starsFunc(evt)}
                                       className="form-control form-control-lg"
                                       placeholder="Enter Pin"/>
                            </div>

                            <span id="login-btn" onClick={() => login()}
                                  className="btn btn-primary btn-user btn-block">
                            Login
                        </span>
                            <div id="login-spinner" style={{'display': 'none'}} className="spinner-border"
                                 role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
        ;

}