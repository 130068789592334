import React from "react";

function Sequences(props) {
    return (
        <div className="col grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="align-content-around">Sequence
                        <i className="mdi mdi-lock-open-outline text-success" id="sequence-lock"
                           onClick={() => props.lockUnlock("sequence")}
                           style={{'cursor': 'pointer'}}> </i></h4>
                    <p className="card-description">
                        Select sequence
                    </p>
                    <div className="table-responsive" style={{'height':'50vh'}} id="sequence-div">
                        <table className="table table-bordered">
                            <tbody>

                            {Array.from(Array(101), (e, i) => {
                                if (i % 10 === 0 && i !== 0) {
                                    if (props.sequence !== i) {
                                        return <tr key={i}>
                                            <td onClick={() => {
                                                props.lockUnlock("sequence");
                                                props.setPayload('sequence', i);
                                                props.setSequence(i);
                                            }}>{i}</td>
                                        </tr>
                                    } else {
                                        return <tr key={i}>
                                            <td
                                                className="table-success">{i}</td>
                                        </tr>
                                    }
                                } else {
                                    return null;
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sequences;
