import React from "react";

function Machines(props) {
    return (
        <div className="col grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="align-content-around">Machine
                        <i className="mdi mdi-lock-open-outline text-success" id="machine-lock"
                           onClick={() => props.lockUnlock("machine")}
                           style={{'cursor': 'pointer'}}> </i></h4>
                    <p className="card-description">
                        Select machine no
                    </p>
                    <div className="table-responsive" style={{'height':'50vh'}} id="machine-div">
                        <table className="table table-bordered">
                            <tbody>
                            {props.items.length === 0 && props.workCenter &&
                            <tr>
                                <td>
                                    <div id="machine-spinner" className="spinner-border"
                                         role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </td>

                                <td>
                                    <small>
                                        Loading will not stop if no items found!
                                    </small>
                                </td>
                            </tr>
                            }
                            {props.items && props.items.map(item => {
                                if (props.machine !== item.id) {
                                    return (
                                        <tr key={item.id + '-' + item.name} onClick={() => {
                                            props.lockUnlock("machine");
                                            props.setPayload('machine', item.id);
                                            props.setMachine(item.id);
                                        }}>
                                            <td>{item.name}</td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={item.id + '-' + item.name}
                                            className="table-success">
                                            <td>{item.name}</td>
                                        </tr>
                                    );
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Machines;
