import React from "react";
import Projects from "./Operation/Projects";
import Parts from "./Operation/Parts";
import Sequences from "./Operation/Sequences";
import WorkCenters from "./Operation/WorkCenters";
import Machines from "./Operation/Machines";

export default function Operation(props) {
    return <div className="row">
        <Projects lockUnlock={props.lockUnlockDiv} selectProject={props.selectProject} project={props.project}
                  items={props.projects}/>
        <Parts lockUnlock={props.lockUnlockDiv} part={props.part} setPart={props.setPart} project={props.project}
               items={props.parts}
               setPayload={props.setPayload}/>
        <Sequences lockUnlock={props.lockUnlockDiv} sequence={props.sequence} setSequence={props.setSequence}
                   setPayload={props.setPayload}/>
        <WorkCenters lockUnlock={props.lockUnlockDiv} workCenter={props.workCenter}
                     selectWorkCenter={props.selectWorkCenter}
                     items={props.workCenters} setPayload={props.setPayload}/>
        <Machines lockUnlock={props.lockUnlockDiv} workCenter={props.workCenter} items={props.machines}
                  machine={props.machine}
                  setMachine={props.setMachine}
                  setPayload={props.setPayload}/>
    </div>
}