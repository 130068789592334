import React from "react";

function WorkCenters(props) {
    return (
        <div className="col grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="align-content-around">Work&nbsp;Center
                        <i className="mdi mdi-lock-open-outline text-success" id="work-center-lock"
                           onClick={() => props.lockUnlock("work-center")}
                           style={{'cursor': 'pointer'}}> </i></h4>
                    <p className="card-description">
                        Select work-center
                    </p>
                    <div className="table-responsive" style={{'height':'50vh'}} id="work-center-div">
                        <table className="table table-bordered">
                            <tbody>
                            {props.items && props.items.map(item => {
                                if (item.id !== props.workCenter) {
                                    return (
                                        <tr key={item.id}>
                                            <td onClick={() => {
                                                props.lockUnlock("work-center");
                                                props.setPayload('work_center', item.id);
                                                props.selectWorkCenter(item.id);
                                            }}>{item.name}</td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={item.id}>
                                            <td key={item.id + '-' + item.name}
                                                className="table-success">{item.name}</td>
                                        </tr>
                                    );
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkCenters;
